import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './styles/init.scss'
import './styles/main.scss'

//import App            from './App';
// import  HomePage  from './pages/HomePage';
// import  NotFoundPage  from './pages/NotFoundPage';
const HomePage = React.lazy(() => import('./pages/HomePage.js'))
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage.js'))

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/control" element={<HomePage />} />
          <Route element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
